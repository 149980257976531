
<!--
 * @Description: 品质管理-检查任务-查看检查项
 * @Author: 马俊
 * @Date: 2021-04-27 11:16:21
 * @LastEditors: 马俊
 * @LastEditTime:
 -->
<template>
    <div class="qualityInspectionView-wrapper">
        <form-panel ref="formPanel" v-bind="submitConfig" :form="form" :submitBefore="submitBefore" @update="update" :submitUrl="submitUrl">
            <table-panel :tableData="tableData" :hasOperateColumn="false" :headers="headers" :autoHeight="true" :showOverflowTooltip="false">
            </table-panel>
        </form-panel>
    </div>
</template>

<script>
import {
    queryURL,
    editURL
} from './api'
import { getScoreHeader, getRectifyHeader } from './helper.js'
import { createImgVNode } from 'common/vdom'
import { TablePanel, CheckboxPlus } from 'components/bussiness'

export default {
    name: 'qualityInspectionView',
    components: {
        'table-panel': TablePanel,
        CheckboxPlus
    },
    computed: {
        headers () {
            const { displayModel = 1 } = this.reportDetail
            let scoreHeader =  getScoreHeader.call(this, this.isEdit, displayModel)
            let rectifyHeader = getRectifyHeader.call(this, this.isEdit)
            return [...this.headers1, ...scoreHeader, ...rectifyHeader, ...this.headers2]
        },
        submitUrl () {
            return this.isEdit ? editURL + '?type=1' : ''
        }
    },
    data() {
        const _this = this
        return {
            headers1: [
                {
                    prop: 'itemName',
                    label: '检查项名称'
                },
                {
                    prop: 'itemCriterion',
                    label: '检查标准'
                },
                {
                    prop: 'itemDescription',
                    label: '检查方法及判定细则'
                },
                {
                    prop: 'isNoAccount',
                    label: '是否为不涉及项',
                    formatter(row, prop) {
                        // 1是 0否
                        return row[prop] == 0? '否': '是'
                    }
                },
                {
                    prop: 'executeTime',
                    label: '检查时间'
                },
                {
                    prop: 'executeUserName',
                    label: '检查人'
                },                
            ],
            headers2: [
                {
                    prop: 'scenePhotos',
                    width: 220,
                    label: '现场照片',
                    formatter(row, prop) {
                        let nodeList = []
                        row[prop] && row[prop].split(',').forEach(imgUrl => {
                            nodeList.push(createImgVNode(_this, row, prop, {
                                imgUrl,
                                style: {
                                    marginRight: '2px',
                                    marginBottom: '2px'
                                }
                            }))
                        })
                        const VNode = _this.$createElement('div',  {
							style: {
                                'display': 'flex',
                                'flex-wrap': 'wrap',
							}
						}, nodeList)
                        return VNode
                    }
                }
            ],
            form: {},
            submitConfig: {
                queryUrl: queryURL,
            },
            tableData: [],
            reportDetail: {},
            isEdit: false,
        }
    },
    mounted () {
        const { taskId, isEdit } = this.$route.query
        this.isEdit = isEdit ? true : false
        if (taskId !== undefined) {
            this.$setBreadcrumb(this.isEdit ? '编辑' : '查看')
            this.$refs.formPanel.getData({ taskId: taskId,  type: 1 })
        }
    },
    methods: {
        submitBefore() {
            return this.tableData;
        },
        update(data) {
            Object.keys(data).forEach(key => {
				if ([undefined, null, ''].includes(data[key])) {
					return
				}
				this.$set(this.reportDetail, key, data[key])
			})
            const { taskDetailList = [], rectificationProgress } = data
            // if (![1, 2].includes(rectificationProgress)) {
            // 	this.submitConfig.submitUrl = ''
            // }
            this.tableData = taskDetailList
        },
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__content{
	margin: 0 !important
}
::v-deep .el-table{
	.cell{
		overflow: unset;
	}

	.vnode-wrapper{
		overflow: unset;
	}

} 
</style>
