import { checkScoreOps, checkScoreMap } from './map'

const getScoreDetailHeader = function (displayModel) {
    let arr = []
    if (displayModel == 2) {
        arr.push({ 
            prop: 'checkScore',
            label: '检查得分',
            formatter: (row, prop) => {
                return checkScoreMap[row[prop]] || ''
            }
        })
    }else{
        arr.push({
            prop: 'maxScore',
            label: '检查分值',
        })
        arr.push({ 
            prop: 'checkMark',
            label: '检查得分'
        })
    }
    return arr
}
const scoreValidator = function (row) {
    return function (rule, value, callback) {
        const { checkMark, maxScore } = row;
        if (checkMark && !(+checkMark >= 0 && +checkMark <= +maxScore)) {
            callback(new Error('请输入正确得分'))
        } else {
            callback()
        }
    }
}
const getScoreEditHeader = function (displayModel) {
    let arr = []
    if (displayModel == 2) {
        arr.push({ 
            prop: 'checkScore',
            label: '检查得分',
            formatter: (row, prop) => {
                return this.$createElement('checkbox-plus', {
                    props: {
                        value: row[prop] + '',
                        type: 'radio',
                        options: checkScoreOps(),
                        disabled: row.status == 1
                    },
                    on: {
                        'update:value' (newValue) {
                            row[prop] = newValue
                        },
                    }
                })
            }
        })
    }else{
        arr.push({
            prop: 'maxScore',
            label: '检查分值',
        })
        arr.push({ 
            prop: 'checkMark',
            label: '检查得分',
            formatter: (row, prop) => {
                return this.$createElement('el-form-item', {
                    props: {
                        prop: prop,
                        rules: [
                            {
                                validator: scoreValidator(row),
                                trigger: 'blur'
                            },
                        ]
                    },
                    style: {
                        margin: 0
                    }
                }, 
                [
                    this.$createElement('v-input', {
                    props: {
                        value: row[prop],
                        width: 'auto',
                        disabled: row.status == 1
                    },
                    on: {
                        input (newValue, el) {
                            // row[prop] = newValue
                            let val = clearNoNum(newValue)
                            row[prop] = Number.parseFloat(val)
                            el.changeModel(val)
                        },
                    }
                    })
                ]
                )
            }
        })
    }
    return arr
}
const getRectifyHeader = function (isEdit) {
    let arr = []
    if (isEdit) {
        arr.push({
            prop: 'checkDetail',
            label: '检查详情',
            formatter: (row, prop) => {
                return this.$createElement('v-textarea', {
                    props: {
                        value: row[prop],
                        placeholder: "请输入备注信息",
                        width: 'auto',
                        disabled: row.status == 1
                    },
                    on: {
                        input(newValue) {
                            row[prop] = newValue
                        },
                    }
                })
            }
        })
    }else {
        arr.push({
            prop: 'checkDetail',
            label: '检查详情',
        })
    }
    return arr
}
const getScoreHeader = function(isEdit, displayModel) {
    return isEdit ? getScoreEditHeader.call(this, displayModel) : getScoreDetailHeader.call(this, displayModel)
}
const clearNoNum = function clearNoNum(item)
{
    //先把非数字的都替换掉，除了数字和.
    item = `${item}`.replace(/[^\d.]/g,"");
    //保证只有出现一个.而没有多个.
    item = item.replace(/\.{2,}/g,".");
    //只能输入两个小数
    item = item.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');
    return item
}
export {
    getRectifyHeader,
    getScoreHeader,
    getScoreEditHeader,
    clearNoNum
}