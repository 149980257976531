var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "qualityInspectionView-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitBefore: _vm.submitBefore,
              submitUrl: _vm.submitUrl,
            },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c("table-panel", {
            attrs: {
              tableData: _vm.tableData,
              hasOperateColumn: false,
              headers: _vm.headers,
              autoHeight: true,
              showOverflowTooltip: false,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }